import type { Promotion } from "@/types";

const useHomePage = () => {
	const { data: appInitData } = useAppInitData();
	const isGuest = useIsGuest();
	const {
		data: pageData,
		pending: loading,
		jackpotGames,
		newGames,
		gamesWithWeekGame: popularGames,
		hotGames,
		refresh
	} = useHomeData({ cached: false, server: true });
	const { data: allGamesResponse } = useGetGameFiltersData();
	const { select } = useGamesState();
	const { isSweepStakes } = useSwitchMode();
	const { t } = useT();

	const banners = computed(() => {
		if (!isGuest.value && appInitData.value?.bannerPresetPackage) {
			return [
				{
					type: "bannerPresetPackage",
					...appInitData.value.bannerPresetPackage
				},
				...(pageData.value?.banners || [])
			];
		}
		return pageData.value?.banners || [];
	});
	const filteredBanners = computed(() =>
		banners.value.filter(
			(banner) =>
				!((banner.type === "referral" && !appInitData.value?.isShowReferralsInfo) || banner.type === "rushHours")
		)
	);
	const favoriteGames = computed(() => select(appInitData.value?.favoriteGames || []));
	const lastGames = computed(() => select(appInitData.value?.lastGames || []));
	const seoData = computed(() => pageData.value?.seo);
	const gameOfWeek = computed(() => select(pageData.value?.gameOfWeek || [])?.[0]);
	const promotions = computed(() => {
		if (!pageData.value?.promotions) {
			return Array(6).fill({
				skeleton: true
			});
		}
		return pageData.value.promotions.map((item) => createDecorator(t)(item as Promotion, isSweepStakes.value));
	});

	return {
		pageData,
		loading,
		jackpotGames,
		hotGames,
		popularGames,
		newGames,
		favoriteGames,
		lastGames,
		banners,
		gameOfWeek,
		filteredBanners,
		allGamesResponse,
		seoData,
		promotions,
		refresh
	};
};

export default useHomePage;
